@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.resource {
  &-wrapper {
    .eyebrow {
      margin-bottom: 0;
    }

    .heading {
      margin-top: 0;
    }
  }

  &-categories {
    font-family: var(--sp-font-secondary);
    -webkit-font-smoothing: antialiased;
    margin-top: $spacer * 0.8;
  }

  &-date {
    font-style: italic;
    margin-bottom: $spacer;

    * {
      display: inline;
    }
  }

  &-media {
    margin-bottom: $spacer;
  }
}

.node-teaser.resource {
  padding: $spacer*1.5 0;
  border-bottom: solid 1px var(--sp-color-medium-gray);

  @include media-breakpoint-up(md) {
    display: flex;
  }

  .image-wrapper {
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      width: 25%;
      padding-right: $spacer;
    }
  }

  .resource-featured-image {
    max-width: 250px;

    @include media-breakpoint-up(md) {
      max-width: 168px;
    }

    img {
      border-radius: 50%;
    }
  }

  .summary {
    margin-top: $spacer * 0.8;
    p {
      line-height: 1.4;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  .heading--teaser {
    margin-bottom: 0;
  }

  .resource__meta--date {
    display: inline;
    font-style: italic;

    * {
      display: inline;
      font-style: italic;
    }
  }

  h4 {
    margin-top: 0;
  }
}

.paragraph__resourcelist,
.paragraph__content-reference {
  .node-teaser.resource {
    .image-wrapper {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 200px !important;
        padding-right: $spacer;
      }
    }
  }
}

.paragraph__threecolumn {
  .paragraph__resourcelist,
  .paragraph__content-reference {
    .node-teaser.resource {
      flex-direction: column;
      .resource-featured-image {
        margin-bottom: 0.5rem;
      }
    }
  }
}
