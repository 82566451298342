@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__storylist {
  font-family: var(--sp-font-primary);
  padding-bottom: $spacer * 4;

  .featuredWrapper {
    margin-bottom: $spacer * 4;
  }

  .nonFeaturedWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    
    .story-card {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      .story-card {
        border-bottom: 1px solid var(--sp-color-medium-gray);
        width: inherit;
        padding-bottom: 30px;

        &:last-of-type {
          border-bottom: none;
        }

        img {
          display: none;
        }
      }
    }

    .nonFeaturedCard {
      width: 100%;
      padding-bottom: $spacer * 1.5;

      @include media-breakpoint-up(lg) {
        max-width: 30%;
      }

      .story-featured-image {
        img {
          position: relative;
          display: none;
          border-bottom-right-radius: 72px;

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }
      }
    }

    .date {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      font-family: var(--sp-font-primary);
      font-weight: 700;
      @include font-size(14px);
      line-height: 1.71;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--sp-color-dark-blue);
    }

    .heading {
      margin-top: 0;
      font-family: var(--sp-font-primary);
      font-weight: 700;
      @include font-size(24px);
      line-height: 1.33;
      a {
        color: var(--sp-color-blue);
      }
    }
  }
}

.story-card {
  &.featured {
    margin-top: $spacer * 2;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: 2.5em;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 !important;
      margin: 0 !important;
    }

    .story-content--wrapper {
      display: flex;
      flex-direction: column;
    }

    .story-content--inner,
    .story-featured-image {
      position: relative;
      max-width: calc(100% + 4.55em);
      padding-right: 15px;
      padding-left: 15px;

      @include media-breakpoint-up(lg) {
        min-width: 50%;
      }
    }

    .story-featured-image {
      padding: 0;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        background-color: var(--sp-color-light-gray);
        max-width: 60%;
      }

      .lazyload-wrapper {
        height: 100%;
      }

      img {
        height: 100%;
        object-fit: cover;
        position: relative;
        border-bottom-right-radius: 72px;
      }
    }

    .story-content--inner {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: var(--sp-color-light-gray);
      color: var(--sp-color-dark-blue);
      width: 100%;
      flex: 1;
      padding: 1.5rem;
      @include font-size(17px);
      line-height: 1.4;
      border-top-right-radius: 72px;

      .story-card__meta {
        text-transform: none;
        font-weight: 700;
        @include font-size(17px);
        margin-bottom: 1rem;
      }

      .heading {
        margin-top: 0;
        @include margin-bottom(1.5rem);
        @include font-size(26px);

        a {
          color: var(--sp-color-blue);
        }
      }
    }

    .story-content--top-decorator {
      position: relative;
      left: -80px;
      z-index: 2;
      mix-blend-mode: multiply;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .story-content--bottom-decorator {
      position: relative;
      right: 0;
      align-self: flex-end;
      margin-right: -1.5rem;
      margin-top: 1rem;
    }
  }

  &__meta {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.71;
  }
}


.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__storylist {
    .featuredWrapper {
      margin-bottom: 2rem;
    }

    .nonFeaturedCard {
      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
    }

    .nonFeaturedWrapper {
      flex-direction: column;

      .story-card {
        border-bottom: 1px solid var(--sp-color-medium-gray);
        width: inherit;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        &:last-of-type {
          border-bottom: none;
        }

        .date {
          margin-top: 0;
        }

        img {
          display: none;
        }
      }
    }

    .story-card {
      &.featured {
        flex-direction: column;
        justify-content: flex-end;

        .story-featured-image {
          max-width: calc(100% + 4.55em);
          padding: 0;
          background: transparent;

          img {
            max-width: 100%;
          }
        }

        .story-content--inner {
          max-width: 650px;
          right: 0;
          border-top-right-radius: 72px;

          @include media-breakpoint-down(md) {
            border: none;
          }
        }

        .story-content--top-decorator {
          display: none;
        }
      }
    }
  }
}
