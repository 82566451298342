@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../config.scss";

.main-sidebar {
  margin-top: 0;
}
.sidebar-menu {
  font-family: var(--sp-font-primary);
  border-bottom: 0;
  box-shadow: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .navbar-nav {
    width: 100%;
  }

  .navbar-collapse,
  .navbar-toggler {
    margin: 0 auto;
    padding: 0 $spacer * 0.5;
    @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint) {
          max-width: $container-max-width;
          padding-right: var(--bs-gutter-x, 0.85rem);
        padding-left: var(--bs-gutter-x, 0.85rem);
        }  
    } 
  }

  .navbar-collapse {
    background-color: var(--sp-color-light-gray);
    padding-top: 24px;
    padding-bottom: 24px;

    @include media-breakpoint-down(md) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @include media-breakpoint-up(lg) {
      border-top-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }
  }

  .dropdown-toggle {
    white-space: normal;

    &:after {
      content: none;
    }
  }

    .has-dropdown > .nav-link {
    white-space: normal;
    display: flex;
    align-items: center;

    &:after {
      border: 0;
      content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2L10.59 0.589996L6 5.17L1.41 0.589996L-6.16331e-08 2L6 8L12 2Z' fill='%230B66DB'/%3E%3C/svg%3E");
      margin-left: auto;
      margin-right: 0.5rem;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  .has-dropdown .nav-link.dropdown-toggle:after {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .navbar-toggler {
      text-align: left;
    background-color: var(--sp-color-purple);
    font-family: var(--sp-font-primary);
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $white;
    width: 100%;
    position: relative;
    padding: 12px 32px;

    .plus-minus-toggle {
      cursor: pointer;
      height: 100%;
      position: absolute;
      width: 21px;
      top: 23px;
      right: 32px;

      &:before,
      &:after {
        background: var(--sp-color-yellow);
        content: "";
        height: 3.2px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
        transition: transform 150ms ease;
      }

      &:after {
        transform-origin: center;
      }

      &.collapsed {
        &:after {
          transform: rotate(90deg);
        }
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  .navbar-toggle-word {
    @include font-size(14px);
    font-weight: bold;
    line-height: 1.71;
    color: $white;
    font-family: var(--sp-font-primary);
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%;

    &.dropdown {
      padding-left: $spacer * 0.8;
      padding-top: $spacer;

      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }

    .nav-item {
      padding: 0.2em 0;
      width: 100%;

      &:last-of-type {
        border: none;
      }

      &.top-level {
        padding-top: 0;
        border-bottom: 0;

        .nav-link {
          padding-top: 0;
          font-weight: 700;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: var(--sp-color-dark-blue);
        }
      }

      .nav-link {
        font-family: var(--sp-font-primary);
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
        color: var(--sp-color-dark-blue);

        &.active, &[aria-current] {
          color: var(--sp-color-purple) !important;
          font-weight: bold;
        }

        &:hover {
          color: var(--sp-color-purple);
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}
