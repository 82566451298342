@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.event-content-wrapper {
  position: relative;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 0 $spacer * 3;
    margin: 0 auto;
    max-width: 1048px;
  }

  &.has-featured-image {
    .events-featured-image {
      position: relative;
      margin-bottom: 40px;
    }
  }

  .share__wrapper {
    margin-top: 1.5rem;
  }

  .more-event-info {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

.event-information-wrapper {
  background: var(--sp-color-light-gray);
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 40px;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }

  .field-container {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: var(--sp-color-dark-blue);
    margin-bottom: 1rem;

    svg {
      color: var(--sp-color-blue);
      font-size: 24px;
      margin-right: 0.5rem;
    }
  }

  p {
    margin-bottom: 0;
  }

  .series-list {
    padding: 0;
    list-style-type: none;
  }
}

.speaker {
  font-family: var(--sp-font-primary);
  font-size: 17px;
  line-height: 1.41;
}

.category {
  font-family: var(--sp-font-primary);
  font-size: 14px;
  color: $dark-gray;
}

.event-content {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    padding-left: $spacer * 3;
    margin-top: 0px;
  }
}
