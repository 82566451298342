@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.secondary-cta {
  background-color: var(--sp-color-light-purple);
  margin-top: 80px;
  padding-top: 40px;
  padding-bottom: 40px;

  &__right {
    display: flex;
    justify-content: flex-end;
  }
}

footer {
  background: var(--sp-color-purple);
  margin-bottom: -10px;
  padding: $spacer * 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  color: $white;
  font-family: var(--sp-font-primary);

  @include media-breakpoint-up(md) {
    padding: $spacer * 3 $spacer $spacer * 2 $spacer;
  }

  div[class*="col"] {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }

  .footer__logo-container {
    margin-bottom: 2rem;
    
    .footer__logo {
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
    }
  }

  .footer__address,
  .footer__email,
  .footer__phone,
  .footer-contact p {
    span {
      display: block;
    }
  }

  .footer__additional-info {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid var(--sp-color-light-purple);
    @include font-size(14px);
  }

  .footer__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-down(lg) {
      align-items: flex-start;
    }

    > :first-child {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  // What are these? Are they in use?
  .footer__cta-primary {
    padding: 0.8em 1.5em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.28px;
    margin-bottom: $spacer;
    display: inline-block;

    &:hover {
      background-color: darken($orange, 10%);
      text-decoration: none;
    }
  }

  .footer__social-icons-wrapper {
    margin-bottom: 2rem;

    svg {
      height: 25px;
      width: 25px;
      fill: var(--sp-color-purple);

      &:hover,
      &:focus {
        fill: var(--sp-color-dark-blue);
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0.5em 1em 0.5em 0;
      background-color: $white;
      border-radius: 50%;
      padding: 7.5px;
    }
  }
}
