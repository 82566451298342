// Sites Pro Defaults
$shale-blue: #0577b1;
$dark-gray: #464343;
$between-med-light-gray: #b5b5b5;
$off-white: #f3f2f1;
$orange: #f18f33;
$light-green: #a1b70d;
$white: #fff;
$navbar-dark-color: #87288e;
$navbar-dark-toggler-border-color: $white;

// Adjust the grid breakpoints to make the max width wider for desktop
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1300px,
  // Extra extra large screen / extra wide desktop
  xxl: 1440px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px,
  xxl: 1340px,
);

// Import Google fonts
//@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,700&display=swap");

$font-sans-serif: "Work Sans", arial, helvetica, sans-serif;
$font-serif: "Work Sans", arial, helvetica, sans-serif;
$font-heading: var(--sp-font-primary);
$enable-responsive-font-sizes: true;
$link-color: var(--sp-color-bright-blue);
$link-decoration: none;
$line-height-base: 1.7;

// Spacer and gutter override
$spacer: 1.1em;
$grid-gutter-width: 1.7rem;

// Import custom mixins
@import "@dws-contributes/sitespro-gatsby-base/src/components/_utils/mixins.scss";

// Import Bootstrap essential variables and functions
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
