@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.news-title,
.news-content {
  @include media-breakpoint-up(md) {
    padding: $spacer $spacer * 3;
    margin: 0 auto;
    max-width: 1048px;
  }
}

.news-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

  h1,
  h2 {
    margin: 0 0 1rem;
  }

  h2 {
    color: var(--sp-color-purple);
    font-weight: 400;
    @include font-size(24px);
    line-height: 1.33;
    margin-bottom: 1.5rem;
  }

  .date {
    font-weight: 700;
    @include font-size(14px);
    line-height: 1.7;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .field-label.authors {
    font-family: var(--sp-font-primary);
    font-style: italic;
    font-weight: 400;
    @include font-size(16px);
    line-height: 1.5;
    color: var(--sp-color-dark-blue);
    text-transform: none;
  }
}

.main-content--news {
  .row {
    margin-top: $spacer * 3;
  }

  .slideshow {
    margin-top: 50px;
  }
}

// TODO: This is being used globally for field-label throughout the base - should it be here?
.field-label {
  font-family: var(--sp-font-primary);
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1rem;
  letter-spacing: 0.5px;
  color: var(--sp-color-purple);

  &:first-of-type {
    margin-top: 0;
  }
}

.featured-image.news {
  margin-bottom: 40px;

  img {
    border-top-right-radius: 75px;
    border-bottom-left-radius: 75px;
  }
}
