@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.event-card {
  padding: 1.4 * $spacer 1.9 * $spacer;
  background-color: var(--sp-color-light-purple);
  border-bottom-left-radius: 50px;
  color: var(--sp-color-dark-blue);
  margin-bottom: 1.5rem;

  .time {
    &__time {
      margin-bottom: 0;
    }
  }

  .date {
    font-weight: bold;
    font-size: 17px;
    line-height: 1.4;
  }

  .heading {
    margin-top: 3rem;

    a {
      color: var(--sp-color-blue);
    }
  }

  &__titlewrapper {
    .eyebrow {
      margin-bottom: 0;
    }
  }

  &__status {
    color: $orange;
    font-style: italic;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .field-container {
    display: flex;
    align-items: center;
    word-break: break-word;
    font-family: var(--sp-font-primary);

    a[href*="http"]:not([href*="-files"]),
    a {
      color: var(--sp-color-dark-blue);
      background-color: transparent;

      &:hover {
        text-decoration: underline;

        &::after {
          background-color: transparent;
        }
      }
    }

    svg {
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }

    p {
      margin: 0;
    }

    .date,
    .time {
      max-width: 210px;
      line-height: 24px;
    }
  }
}

// Teaser modifier - used on the events page and nested inside 2- and 3-columns PB
.paragraph__twocolumn,
.paragraph__threecolumn {
  .event-card {
    .cardHeaderWrapper {
      margin-bottom: $spacer;
    }

    .date {
      margin-top: 0;
    }
  }
}

.event-card--teaser {
  border: none;
  border-bottom: solid 1px var(--sp-color-light-gray);
  margin-top: $spacer;

  .cardHeaderWrapper {
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      display: inline-grid;
      grid-template-columns: 100px 1fr;
    }
  }

  .date {
    margin-top: 0;
  }

  .heading {
    text-transform: initial;
    margin-top: 0;
  }
}

.event-card--search {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $white;
  border-bottom-left-radius: 0;
  color: var(--sp-color-blue);
  border-bottom: 1px solid var(--sp-color-medium-gray);

  &:last-of-type {
    border-bottom: 0;
  }

  .cardHeaderWrapper {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .event-card__cardInfoWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    color: var(--sp-color-dark-blue);

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-left: 40px;
    }

    a[href*="http"]:not([href*="-files"]),
    a {
      color: var(--sp-color-bright-blue);
    }

    .location {
      order: 3;
    }

    .time-container {
      order: 2;
      margin-bottom: 0.5rem;
    }

    h4.heading {
      font-size: 1.5rem;
      margin-top: 0;
      order: 1;

      a[href*="http"]:not([href*="-files"]),
      a {
        color: var(--sp-color-blue);
      }
    }

    .field-container {
      svg {
        fill: var(--sp-color-blue);
      }
    }
  }
}
