@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.eyebrow {
  text-transform: uppercase;
  font-family: var(--sp-font-primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: $dark-gray;

  &--warning {
    color: var(--sp-color-red);
    font-style: italic;
  }
}