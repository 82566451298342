@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.divider {
  @include margin-top(40px);
  @include margin-bottom(24px);
  height: 8px;
  max-width: 212px;
  background-color: var(--sp-color-yellow);
  color: var(--sp-color-yellow);
  border: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  opacity: 1;
}

.divider--long {
  max-width: 100%;
  height: 5px;
}
