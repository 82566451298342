@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__hero-carousel {
  margin-bottom: 0;

  @include media-breakpoint-down(lg) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .control-prev,
  .control-next {
    border: none;
    background-color: transparent;
    padding: 0;

    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: $spacer * 1;
      width: 36px;
      height: 36px;
      border-radius: 50%;

      @include media-breakpoint-up(lg) {
        width: 64px;
        height: 64px;
      }
    }
  }

  .control-prev-icon {
    left: 2px;
    background: url("./../../../images/slideshow_prev.svg") center center
      no-repeat;
    background-size: 36px;

    @include media-breakpoint-up(lg) {
      left: 24px;
      background-size: 64px;
    }
  }

  .control-next-icon {
    right: 2px;
    background: url("./../../../images/slideshow_next.svg") center center
      no-repeat;
    background-size: 36px;

    @include media-breakpoint-up(lg) {
      right: 24px;
      background-size: 64px;
    }
  }

  &__item-slide {
    position: relative;
    &.active {
      display: flex;
    }
  }

  &__item-slide-content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__bottom-placeholder {
    display: none;
  }

  &__image {
    transition: 0.25s opacity;

    .lazyload-wrapper {
      height: 100%;
      img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }

  &__info {
    width: 100%;
    background-color: var(--sp-color-green);
    padding: 1.5rem 3rem;
    min-height: 200px;

    @include media-breakpoint-up(lg) {
      min-width: 42%;
      border-bottom-right-radius: 100px;
      padding: 1.5rem;
      padding-top: 4rem;
    }
  }

  &__content {
    flex-grow: 1;
    color: var(--sp-color-dark-blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .eyebrow {
      text-align: left;
      color: var(--sp-color-blue);
      font-size: 1.1rem;
      margin-bottom: $spacer * 0.33;
    }

    h4 {
      text-align: left;
      text-transform: unset;
      color: var(--sp-color-dark-blue);
      font-weight: 300;
      line-height: 1.3;
      @include font-size(40px);
      margin: 0;
      margin-bottom: 1.5rem;
    }

    a.button {
      color: var(--sp-color-blue);
      margin-bottom: 1.5rem;

      .button__icon {
        background-color: var(--sp-color-blue);
      }
    }

    .slide-number {
      font-weight: 600;
      @include font-size(20px);
      line-height: 1.2;
    }
  }

  &__title-list {
    display: none;
  }
}
