@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.search-interface {
  input[type="search"],
  select.custom-select {
    width: 300px;
    margin-right: 0;
    border-radius: 0;
    border-right: 0;
    background-color: var(--sp-color-light-gray);
    border-color: var(--sp-color-medium-gray);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-family: var(--sp-font-primary);
    background-clip: unset;
    padding: 0.375rem 0.75rem;
    height: calc(1.7em + 0.75rem + 2px);

    &:-internal-autofill-selected {
      background-color: var(--sp-color-light-gray) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  select.custom-select {
    border-radius: 25px;
    border-right: solid 1px #b5b5b5;
    padding-right: 1rem;
  }

  button[type="submit"] {
    display: flex;
    align-items: center;
    border-radius: 0;
    border-left: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: var(--sp-color-light-gray);
    border-color: var(--sp-color-medium-gray);
    color: var(--sp-color-purple);
    height: calc(1.7em + 0.75rem + 2px);

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .pagination-link {
    font-family: var(--sp-font-primary);
    &.active {
      background-color: var(--sp-color-bright-blue);
    }
  }

  .search-facets {
    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .collapse-facets-btn {
      background-color: var(--sp-color-purple);
      color: $white;
      border-bottom: 0;
      margin-bottom: 0;
      padding: 12px 32px;

      .plus-minus-toggle {
        top: 23px;
        right: 32px;

        &:before,
        &:after {
          background: var(--sp-color-yellow);
        }
      }
    }
  }

  .search-facet-group {
    h3 {
      font-size: 14px;
      line-height: 1.7;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .search-pagination {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      flex-flow: row nowrap;
    }
  }
  .pages {
    .pagination-link {
      &:hover {
        border-radius: 50%;
      }
    }
  }
  .pagination-link {
    font-family: var(--sp-font-primary);
    margin: 0 10px;
    @include font-size(14px);
    font-weight: bold;
    height: 32px;
    width: 32px;
    text-align: center;
    letter-spacing: 1px;

    &.active {
      background-color: var(--sp-color-bright-blue);
      color: white;
      border-radius: 50%;
    }
  }
}
