@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../config.scss";

.actionmenu li.nav-item {
    padding: 0;

    &:last-of-type {
        a.nav-link {
            margin-bottom: 0;
        }
    }

    a.nav-link {
        margin: 0;
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
    }


} 

.actionmenu {
    margin-bottom: 1.5rem;
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-right: $spacer*0.5;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    li {
      font-family: var(--sp-font-primary);
      font-size: 0.9rem;
      font-weight: 600;
      display: flex;
      padding: 8px 0;

      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
