@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.blog-post {
  &__content {
    position: relative;
    margin: 0;
    padding: 0;
    background: $white;

    @include media-breakpoint-up(md) {
      padding: $spacer $spacer * 3;
      margin: 0 auto;
      max-width: 1048px;
    }

    h1.heading {
      margin-top: 0;
    }

    .meta__wrapper {
      @include margin-top(1.5rem);
      @include margin-bottom(40px);
    }

    .blog-featured-image {
      @include margin-bottom(40px);

      img {
        border-top-right-radius: 75px;
      }
    }

    .share__wrapper {
      @include margin-bottom(40px);
    }
  }

  &__content--noimage {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }
  }
}

.node-teaser__blog {
  padding: 1.1em 0;
  border-bottom: solid 1px var(--sp-color-medium-gray);
  word-break: break-word;

  p {
    @include font-size(17px);
    line-height: 1.4;
  }

  .news-featured-image {
    img {
      border-top-right-radius: 32px;
    }
  }

  .node-teaser__meta {
    font-family: var(--sp-font-primary);
    color: var(--sp-color-dark-blue);
    margin-top: $spacer * 0.5;

    & > *,
    .date-lineview {
      display: inline;
    }

    &--dot {
        svg {
            fill: $orange;
        }
    }

    &--date {
      letter-spacing: 0.5px;
    }
  }

  .node-teaser__meta--authors {
    font-weight: normal;

    a {
      color: var(--sp-color-dark-blue);
      text-transform: none;
      font-family: var(--sp-font-primary);
      font-weight: 600;

      &:hover {
        color: $white;
      }
    }
  }
}
