@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.date-cardview {
  background-color: var(--sp-color-purple);
  border-radius: 50%;
  padding: $spacer * 0.6 $spacer * 0.8;
  color: $white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-month {
    font-family: var(--sp-font-primary);
    font-weight: 600;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &-day {
    font-family: var(--sp-font-primary);
    font-size: 20px;
    display: block;
    line-height: 1.2;
    font-weight: 600;
  }
}