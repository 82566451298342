@mixin button-style($button-color) {
  border: 1px solid $button-color;
  background: $button-color;

  &:hover {
    background-color: darken($button-color, 10%);
    text-decoration: none;
  }
}

.button,
a.button {
  border-radius: 100px;
  color: $white;
  background-color: var(--sp-color-purple);
  margin: $spacer 0;
  // TODO: Mobile button padding
  padding: 14px 40px;
  display: inline-flex;
  align-items: center;
  text-transform: none;
  letter-spacing: initial;

  @include media-breakpoint-up(sm) {
    padding: 14px 40px;
  }

  &:hover {
    background-color: var(--sp-color-button-hover);;
    transition: background-color 0.2s ease-out;
    color: $white;
    text-decoration: none;
  }

  &__text,
  span {
    font-family: var(--sp-font-primary);
    font-size: 0.9rem;
    font-weight: 700;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  // Utility Nav
  &--cta {
    background-color: var(--sp-color-blue);
    color: $white;

    &:hover {
      background-color: var(--sp-color-dark-blue);
    }
  }

  &--secondary-cta {
    background-color: var(--sp-color-yellow);
    color: var(--sp-color-dark-blue);

    &:hover {
      background-color: #ccae00;
      color: var(--sp-color-dark-blue);
    }
  }

  &--plain {
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      box-shadow: none;
    }
  }

  &--footer-cta {
    margin: 0;
  }

  &--white {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &--blue {
    background-color: var(--sp-color-blue);
    color: $white;

    &:hover {
      background-color: var(--sp-color-dark-blue);
    }
  }

  &--with-icon {
    color: var(--sp-color-bright-blue);
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:hover {
      color: var(--sp-color-bright-blue);
      box-shadow: none;
      background-color: transparent;
      text-decoration: underline;
    }

    .button__text {
      order: 2;
    }

    .button__icon {
      display: block;
      order: 1;
      background-color: var(--sp-color-bright-blue);
      fill: $white;
      padding: 6px;
      box-sizing: initial;
      border-radius: 50%;
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  &__text + &__icon,
  &__icon + &__text {
    margin-left: 0.5rem;
  }

  &__icon {
    display: none;
    position: relative;
    font-size: 26px;
    transform: rotate(180deg);
    transition: all 0.5s ease-out;
  }
}

// Misc overrides
.main-content {
  a.button::after {
    display: none;
  }
}
