@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


/*
 *  Global for card references
 */
.reference-card {
  margin: 0;
}

.paragraph__content-reference {
  padding-bottom: 2 * $spacer;

  .document-teaser {
    padding-top: 0;
  }

  .person-card__content {
    h3.heading {
      @include font-size(26px);
    }
  }
}