@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../config.scss";

.footer-menu {
  ul {
    width: 100%;
    padding: $spacer 0 0 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0.5rem;
    row-gap: 1 * $spacer;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      padding: 0;

      &:empty {
        padding: 0;
      }
    }

    .nav-item {

      .nav-link {
        font-size: 1rem;
        font-weight: 600;
        padding: $spacer * 0.5 0;
        text-decoration: none;
        color: $white;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }

        &:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
}

.footer-bottom-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: $spacer;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  a {
    font-size: 0.9rem;
    margin-left: $spacer;
    text-decoration: underline;

    @include media-breakpoint-up(md) {
      text-decoration: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }
}
