@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";


.paragraph__hero-image-text {
  .hero-image {
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    }
  }

  &.landscape {
    .hero-image {
      .hero-image__svg {
        width: 80px;
        height: 80px;
        position: absolute;
        bottom: -40px;
        left: -40px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .lazyload-wrapper {
        img {
          border-top-left-radius: 100px;
        }
      }
    }

    .hero-text-wrapper:before {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }
    }
  }

  &.portrait {
    .hero-image {
      .hero-image__svg {
        position: absolute;
        bottom: 0px;
        right: -50px;
        width: 200px;
        height: auto;
        max-height: 120px;

        @include media-breakpoint-up(xl) {
          width: 392px;
          height: 120px;
          right: -200px;
        }
      }

      img {
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }

    .hero-text-wrapper:before {
      background-color: $orange;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
      }
    }
  }

  .row {
    margin: 2rem 0;

    @include media-breakpoint-up(lg) {
      margin: 4rem 0;
    }
  }

  .hero-text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    &:before {
      align-self: flex-start;
      content: "";
      height: 16px;
      width: 185px;
      background-color: var(--sp-color-green);

      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
      }
    }
  }

  .hero-text {
    position: relative;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;

    .eyebrow {
      font-weight: bold;
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--sp-color-blue);
      margin: 0;
    }

    h2 {
      margin: 0;
      margin-bottom: 1rem;
      color: var(--sp-color-purple);
      font-weight: 300;
      @include font-size(40px);
      line-height: 1.4;
    }

    h3 {
      margin-top: 0;
      color: var(--sp-color-blue);
      @include font-size(24px);
      line-height: 1.33;
      font-weight: normal;
      text-transform: none;
    }

    a {
      &.button {
        @extend .button--with-icon;
      }
    }
  }
}
