@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__hero-image {
    .hero-image {
        text-align: left;
        margin: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        min-height: 500px;
    }

    h2.heading--hero {
        margin-top: 0;
        padding-top: 3.5rem;
    }
}