@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.profile {
  padding-top: $spacer * 2;

  .accordion {
    margin-top: 1rem;
  }

  svg {
    margin-right: 5px;
    color: var(--sp-color-blue);
    width: 24px;
    height: 24px;
  }

  .divider--long {
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .profile-meta {
    @include font-size(17px);

    .field-container {
      display: flex;
    }

    .field-label {
      @include font-size(16px);
      line-height: 1.5;
    }

    .row:first-of-type {
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
      }
    }

    h4.heading,
    h5 {
      font-weight: 700;
      @include font-size(16px);
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 0;
    }
  }

  .education {
    display: block;
  }

  &__address span {
    display: block;
    font-style: normal;
    font-family: var(--sp-font-primary);
  }

  &__position {
    font-family: var(--sp-font-primary);
    font-style: italic;
    color: var(--sp-color-purple);
    font-size: 1.25rem;
  }

  &__personal-link {
    font-family: var(--sp-font-primary);
  }

  &__bio {
    @include margin-top(4rem);
    @include margin-bottom(4rem);
  }

  &__related-content-item {
    border-bottom: solid 1px $between-med-light-gray;
    padding: $spacer * 1.5 0;

    p {
      margin-bottom: 8px;

      &.eyebrow {
        color: var(--sp-color-purple);
        margin-bottom: 0;
      }

      a {
        font-weight: 600;
        @include font-size(20px);
        line-height: 1.2;
      }
    }

    .date {
      font-family: var(--sp-font-primary);
      @include font-size(14px);
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &:last-of-type {
      border: none;
    }
  }

  .profile__image {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      margin-right: 1.5rem;
    }

    img {
      border-radius: 50%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  h2.heading:first-of-type {
    margin-top: $spacer * 2;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .accordion-toggle {
    text-transform: capitalize;
  }
}

.profile-teaser {
  margin-bottom: $spacer * 2;
  font-family: var(--sp-font-primary);

  svg {
    margin-right: 8px;
    color: $shale-blue;
  }

  &__name {
    margin-bottom: 0;
    margin-top: $spacer;
    line-height: 1.3;

    a {
      color: var(--sp-color-blue);
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: $spacer * 0.5;
      display: block;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
        color: var(--sp-color-blue);
      }
    }
  }

  &__role {
    font-family: var(--sp-font-primary);
    font-weight: 400;
    @include font-size(17px);
    line-height: 1.4;
    text-transform: initial;
    color: var(--sp-color-dark-blue);
  }

  &__image {
    max-width: 200px;

    img {
      border-radius: 50%;
    }
  }

  h4.heading {
    margin-top: 0;
  }
}

button.reveal-more {
  color: var(--sp-color-bright-blue);
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}
