@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.featuredcard {
  display: flex;
  margin-right: -15px;
  position: relative;
  flex-direction: row;
  margin-right: 0;
  margin-bottom: 50px;

  &--news {
    &.featuredcard--with-image {
      flex-direction: column;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .news-featured-image {
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(xl) {
          max-width: 520px;
          aspect-ratio: 3 /2 ;
          display: flex;
          align-items: flex-end;
        }

        img {
          border-top-right-radius: 75px;
          border-bottom-left-radius: 75px;
          width: 100%;
        }

        .featuredcard__decorator.news {
          position: absolute;
          right: 0;
          bottom: -20px;
          mix-blend-mode: multiply;
        }
      }

      .featuredcard__content {
        background-color: var(--sp-color-light-gray);
        border-bottom-right-radius: 50px;
        width: 100%;
      }
    }
  }

  &--without-image {
    background-color: var(--sp-color-light-gray);
    border-top-right-radius: 75px;
    display: flex;

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 2;
    }
  }

  // content wrapper
  &__content {
    padding: 2rem;
    position: relative;
    //z-index: 150;
    height: 80%;
    bottom: 0;
    align-self: flex-end;
    border: none;
    flex: 1;
  }

  &__meta {
    font-family: var(--sp-font-primary);
    font-size: 17px;

    .date,
    p {
      display: inline-block;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    a {
      color: var(--sp-color-dark-blue);
    }
  }

  &__decorator {
    position: absolute;
    right: 40px;
    bottom: -40px;
  }

  .contentWrapper {
    font-size: 16px;
    line-height: 1.78;
    color: var(--sp-color-dark-blue);
    font-family: var(--sp-font-primary);
    word-break: break-word;
  }

  .heading--featuredcard {
    @include heading-base;

    margin: $spacer * 0.33 0;
    font-weight: 600;
    font-size: 26px;

    a {
      color: var(--sp-color-blue);

      &:hover {
        color: var(--sp-color-blue);
      }
    }
  }
}

div[class^="col-"] > .featuredcard {
  margin-left: 0;
  box-shadow: none;

  .news-featured-image {
    max-width: 100%;
    top: -20px;
  }

  .heading--featuredcard {
    a {
      color: var(--sp-color-blue);
    }
  }

  &__meta {
    color: $dark-gray;

    a {
      color: $dark-gray;

      &:hover {
        color: $white;
      }
    }
  }

  &__content {
    padding: 40px;
    margin: 2 * $spacer;
    position: relative;
    z-index: 150;
    background: white;
    max-width: 100%;
    box-shadow: none;
    right: 0;
  }

  &--without-image {
    .featuredcard__content {
      top: 0;
    }
  }

  .contentWrapper {
    color: $dark-gray;
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .featuredcard {
    flex-direction: column;

    .news-featured-image {
      max-width: 100%;
    }
  }
}
