@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../config.scss";

.header_wrapper {
  .mainmenu .navbar-nav {
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }
}

.mainmenu,
.navbar-light .mainmenu.navbar-nav {
  @include media-breakpoint-down(lg) {
    padding: $spacer 0;
  }

  .nav-item {
    padding: 2px 5px 2px 0;

    @include media-breakpoint-down(xxl) {
      margin-right: 0;
    }
  }

  // link label
  .nav-link {
    font-family: var(--sp-font-primary);
    font-size: 1rem;
    color: $white;
    display: inline-block;
    line-height: 1.4;
    transition: all 0.2s ease;
    appearance: none;
    -webkit-font-smoothing: antialiased;
    padding: $spacer 1.5 * $spacer;

    &:hover {
      background-color: var(--sp-color-yellow);
      color: var(--sp-color-dark-blue);
    }

    @include media-breakpoint-up(lg) {
      font-weight: 600;
    }
  }

  // // link toggle/caret
  .dropdown-toggle {
    transition: all 0.2s ease;

    &[aria-expanded="true"] {
      background-color: var(--sp-color-yellow);
      color: var(--sp-color-dark-blue);
    }
  }

  // dropdown menu
  .dropdown-menu {
    border: none;
    background-color: var(--sp-color-purple);
    box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 25px;
    min-width: 300px;
    padding: 0;
    transition: all 0.2s ease;
    top: 96%;

    .container {
      padding: 0;
    }
  }

  .dropdown-item {
    color: $white;
    font-size: 0.95rem;
    font-weight: 600;
    padding-top: 13px;
    padding-bottom: 13px;

    &:last-of-type {
      border-bottom-right-radius: 25px;
    }

    &:hover,
    &:focus {
      background-color: var(--sp-color-yellow);
      color: var(--sp-color-dark-blue);
      transition: all 0.2s ease;
    }
  }

  // top level link label
  .nav-item {
    padding: 0;
  }

  // mobile styles
  @include media-breakpoint-down(lg) {
    .nav-item {
      width: 100%;
    }

    // Remove the underlining
    .nav-item:hover {
      .nav-link {
        border-bottom: none;
      }
    }

    .nav-link {
      @include font-size(20px);
      line-height: 1.6;
      color: $white;
      font-weight: 400;
      text-transform: initial;
      appearance: none;
      padding: $spacer * 0.2 0;

      @include media-breakpoint-up(lg) {
        padding: $spacer * 0.2 $spacer;
      }

      &:hover {
        background: transparent;
        color: $white;
      }
    }

    .dropdown-menu {
      box-shadow: none;
    }

    .dropdown-link {
      padding: $spacer * 0.2 1.2rem;
    }

    // toggle - change to +/-, align to right
    .dropdown-toggle {
      cursor: pointer;
      width: 100%;
      position: relative;

      &::before,
      &::after {
        background: var(--sp-color-yellow);
        content: "";
        height: 2px;
        right: 5px;
        position: absolute;
        top: 50%;
        width: 16px;
        border: none;
        transition: transform 0.2s ease;
      }

      &::after {
        transform-origin: center;
        background: var(--sp-color-yellow);
      }

      &[aria-expanded="true"] {
        background-color: transparent;
        color: $white;
      }

      &[aria-expanded="false"] {
        &::after {
          transform: rotate(90deg);
        }

        &::before {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-item.nav-link {
      &:first-of-type {
        margin-top: 1rem;
      }

      @include font-size(15px);
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

// Hamburger menu label (MENU)
.navbar-hamburger-button_label {
  display: inline-block;
  font-size: 0.95rem;
  color: $white;
  font-family: var(--sp-font-primary);
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 1px;
}

.navbar-toggler {
  display: block;
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin: 0;
  padding: $spacer * 0.5;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: transform 0.2s ease;
  appearance: none;
  color: $white;
  width: 100%;
  border-top: solid 1px $shale-blue;

  &:focus {
    outline: 2px solid $white;
    box-shadow: none;
  }

  &:active {
    transform: scale(0.99);
  }

  .hamburger-box {
    width: 20px;
    height: 11px;
    display: inline-block;
    position: relative;
    margin-bottom: 1px;
  }

  .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    top: 50%;

    &,
    &::before,
    &::after {
      width: 100%;
      height: 2px;
      content: "";
      display: block;
      background-color: $white;
      border-radius: 1px;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &::before {
      top: -5px;
      transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out,
        background-color 0.15s ease;
    }

    &::after {
      bottom: -5px;
      transition: bottom 0.1s 0.34s ease-in,
        transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19),
        background-color 0.15s ease;
    }
  }

  &[aria-expanded="false"] {
    .label-close {
      display: none;
    }
  }

  &[aria-expanded="true"] {
    .label-menu {
      display: none;
    }

    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        background-color: $shale-blue;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.2s ease-out,
          transform 0.3s 0.24s cubic-bezier(0.215, 0.61, 0.355, 1),
          background-color 0.15s ease;
      }
    }
  }
}
