@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

.node-teaser__news {
  margin-bottom: $spacer;
  line-height: 1.4;

  .node-teaser__meta {
    color: var(--sp-color-dark-blue);
    font-family: var(--sp-font-primary);
    font-weight: 700;
    @include font-size(14px);
    line-height: 1.7;    
    letter-spacing: 0.5px;
    text-transform: none;

    svg {
      fill: $orange;
    }
  }

  .date {
    display: inline-block;
    text-transform: uppercase;

    svg {
      fill: $orange;
    }
  }

  .news-featured-image {
    margin-bottom: $spacer;
    img {
      border-top-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }
  }
}
