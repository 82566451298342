@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__iconrow {
  .heading {
    margin-bottom: 30px;
  }

  .row {
    justify-content: center;
  }
}