@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";

body {
  font-family: var(--sp-font-primary);
  color: var(--sp-color-dark-blue);
  line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--sp-font-primary);
  color: var(--sp-color-purple);
  font-weight: bold;
  text-transform: none;
}

h1 {
  font-weight: 700;
  @include font-size(44px);
  line-height: 1.27;
}

h2 {
  @include font-size(36px);
  line-height: 1.33;
  margin: $spacer 0 $spacer*0.5 0;
}

h3 {
  @include font-size(27px);
  line-height: 1.19;
  text-transform: none;
}

h4 {
  @include font-size(21px);
  line-height: 1.52;
  text-transform: none;
}

h5 {
  @include font-size(18px);
  line-height: 1.33;
}

h6 {
  @include font-size(16px);
  line-height: 1.5;
}

a {
  color: var(--sp-color-bright-blue);
}

main[class^="main-content"],
div[class^="main-content"] {
  a:not(.button):hover {
    background-color: var(--sp-color-bright-blue);
    color: $white;
  }

  .heading {
    a:not(.button):hover {
      background-color: transparent;
      color: inherit;
      text-decoration: underline;
    }
  }

  a[href*="http"]:not([href*="-files"]) {
    fill: var(--sp-color-blue) !important;
  }

  a[href*="http"]:not([href*="-files"]).button {
    span::after {
      filter: brightness(0) invert(1);
    }
  }

  p {
    line-height: 1.4;
  }
}

.announcement__wrapper {
  &.announcement__wrapper--Warning {
    background-color: $orange;
    color: var(--sp-color-dark-blue);
  }

  &.announcement__wrapper--Call-to-action {
    background-color: var(--sp-color-green);
  }

  &.announcement__wrapper--Informational {
    background-color: var(--sp-color-blue);
    a {
      color: $white;
    }
  }
}

// .accordion {
//     &-icon {
//         background-color: $link-color;

//         &:before,
//         &:after {
//             background-color: $white;
//         }
//     }
// }

.list-heading {
  border-bottom: 0;
  margin-bottom: 40px;

  h2 {
    border-bottom: 0;
    text-transform: none;
    padding-bottom: 0;
    font-weight: bold;
  }
}

.bloglist__col .button {
  @extend .button--with-icon;
}

.paragraph__imagecardrow {
  &::after {
    display: none !important;
  }
}

// Accordions - global styles
.accordion {
  .card {
    border: none;
    border-bottom: 1px solid var(--sp-color-medium-gray);
    border-radius: 0px;
  }

  &-toggle {
    background-color: $white;
    border: none;
    width: 100%;
    margin: auto;
    padding: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--sp-color-dark-blue);
    border-bottom: none;
    display: flex;

    &.current {
      background-color: $off-white;
    }

    &:focus {
      box-shadow: none;
      color: black;
      border: none;
      box-shadow: none;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus, &:hover {
      background-color: $off-white;
      border-radius: unset;
    }
  }  

  .questionWrapper {
    margin-left: 2.2em;
  }

  // Accordion toggle icon - change to +/-, align to right
  &-icon {
    cursor: pointer;
    position: relative;
    top: unset;
    left: unset;
    width: 32px;
    height: 32px;
    background: var(--sp-color-bright-blue);
    border-radius: 50%;
    margin-right: 1.5rem;
    flex-shrink: 0;

    &:before,
    &:after {
      background: $white;
      content: '';
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 1.5px);
      width: 20px;
      border: none;
      transition: transform 0.1s ease;
      margin-left: auto;
      margin-right: auto;
    }

    &:after {
      transform-origin: center;
      background: $white;
    }

    button[aria-expanded="false"] & {
      &:after {
        transform: rotate(90deg);
      }
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: var(--sp-font-primary);
    padding: $spacer $spacer*3;
    padding-left: calc(32px + 1.5rem + 20px)
  }
}

.node-teaser {
  padding: $spacer 0;

  &:first-of-type {
    padding-top: 0;
  }
}

.search-results.has-pagination {
  border-bottom: 4px solid var(--sp-color-dandelion);
  margin-bottom: 40px;
}