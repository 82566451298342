@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__media {
  p.eyebrow {
    font-family: var(--sp-font-primary);
    @include font-size(18px);
    font-weight: 700;
    line-height: 1.78;
    color: var(--sp-color-blue);
    margin: 0;
    letter-spacing: 1px;
  }

  .heading {
    margin: 0;
    @include margin-bottom(40px);
    font-family: var(--sp-font-primary);
    @include font-size(27px);
    line-height: 1.19;
    color: var(--sp-color-purple);
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  figcaption,
  .caption {
    font-family: var(--sp-font-primary);
    margin-bottom: 0;
    font-weight: 500;
    @include font-size(14px);
  }
}
