@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.meta-box {
  padding: 24px;
  background-color: var(--sp-color-light-gray);
  color: var(--sp-color-dark-blue);
  overflow-wrap: break-word;
  padding: $spacer;

  @include media-breakpoint-up(md) {
    border-bottom-left-radius: 32px;
    border-top-right-radius: 32px;
  }

  h4 {
    margin: 0 0 16px;
  }

  .date,
  p {
    font-style: italic;
  }

  .field-container {
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 8px;
    margin-bottom: 1em;
    word-break: break-word;

    p {
      margin: 0;
    }

    svg {
      margin-right: 5px;
      color: var(--sp-color-blue);
      width: 24px;
      height: 24px;
    }

    .date,
    .time {
      max-width: 210px;
      line-height: 24px;
    }
  }

  &.news-meta {
    margin-bottom: 50px;
  }
}
