@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";


.paragraph__eventlist {
  margin-bottom: $spacer * 2;

  @include media-breakpoint-up(md) {
    .event-card-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start
    }

    .event-card {
      max-width: 300px;
      flex: 1 1 auto;
    }
  }

  a {
    &.button {
      @extend .button--with-icon;
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__eventlist {
    .event-card-wrapper {
      display: block;
    }

    .event-card {
      max-width: 100%;
    }
  }
}
