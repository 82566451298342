@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.teasercard {
  border-bottom: solid 1px $between-med-light-gray;
  padding: $spacer 0;

  &:last-of-type {
    border-bottom: none;
  }

  &__meta {
    font-family: var(--sp-font-primary);
    letter-spacing: 1px;
    @include font-size(14px);
    line-height: 1.71;

    .date,
    p {
      font-weight: bold;
      display: inline-block;
    }

    .date {
      text-transform: uppercase;
    }

    &--source {

      span {
        svg {
          fill: $orange;
          font-size: 1.5rem;
        }
      }

      a {
        font-weight: 600;
        text-transform: none;
        letter-spacing: normal;
        color: var(--sp-color-dark-blue);
      }
    }
  }

  .heading--teasercard {
    @include heading-base;

    margin: $spacer * 0.33 0;
    font-weight: 600;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;

    a {
      color: var(--sp-color-blue);
    }
  }
}
