@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../config.scss";

.utility_menu_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;

  @include media-breakpoint-up(lg) {
    padding: $spacer;
    height: 48px;
    width: auto;
    margin: 0;
    margin-left: auto;
  }

  .utilitymenu {
    order: 1;
  }

  .actionmenu {
    order: 0;
  }
}

.utilitymenu {
  @include media-breakpoint-up(lg) {
    background-color: var(--sp-color-light-gray);
    padding-left: 1.5 * $spacer;
    padding-right: $spacer;
    border-bottom-left-radius: 50px;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: flex-end;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: stretch;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    li {
      display: flex;

      a.nav-link {
        line-height: 1;
        padding: 13px 0;
        color: var(--sp-color-purple);
        @include font-size(14px);
        font-weight: 600;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
          @include font-size(13px);
          font-weight: 700;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        &:hover {
          text-decoration: underline;
          color: var(--sp-color-blue);
        }
      }

      @include media-breakpoint-up(lg) {
        &:after {
          content: "●";
          font-size: 13px;
          align-self: center;
          color: var(--sp-color-blue);
          margin: 0 1rem;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
