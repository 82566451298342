@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__wysiwyg,
.body,
.card-body,
.main-content {
  @include clearfix;

  .media {
    display: block;
    margin-bottom: $spacer;
    max-width: 100%;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin-bottom: $spacer;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  // CKEditor media customizations
  .caption {
    width: auto;

    & > figcaption {
      display: block;
      max-width: fit-content;
      margin-bottom: $spacer;
      margin-top: -$spacer*0.5;
      color: var(--sp-color-medium-gray);
    }
  } 

  .media--view-mode-full-column-width {
    width: 100%;

		&.player-wrapper {
			margin-top: $spacer*2;
			margin-bottom: $spacer*2;
		}
  }

  @include media-breakpoint-up(md) {
    figure.caption,
    article.media {
      width: auto;   

      &.align-right {
        float: right !important;
        margin-left: $spacer*2;
      }

      &.align-left {
        float: left !important;
        margin-right: $spacer*2;
      }

      &.align-center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      &.media--view-mode-_-2-column-width {
        width: 50%;
        & + figcaption {
          width: 50%;
        }

				&.player-wrapper {
					padding-top: 28.25%;
				}
      }

      &.media--view-mode-_-3-column-width {
        width: 33.33%;
        & + figcaption {
          width: 33.33%;
        }

				&.player-wrapper {
					padding-top: 22.25%;
				}
      } 
    }

    figure.caption > article.media {
      &.align-right,
      &.align-left,
      &.align-center,
      &.media--view-mode-_-2-column-width,
      &.media--view-mode-_-3-column-width {
        width: 100%;
        float: none;

        & + figcaption {
          width: 100%;
        }

				&.player-wrapper {
					padding-top: 60%;
				}
      } 
    }
  }
  
  .table-wrapper {
    overflow-x: auto;
  }
}

// Tables
table {
  font-family: var(--sp-font-primary);
  border: none;
  font-size: 1rem;
  margin-bottom: $spacer;

  caption {
    color: $white;
    background-color: var(--sp-color-blue);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    caption-side: top;
    margin-bottom: 0;
  }

  th, td, caption {
    padding: $spacer * 0.5 $spacer;
    border: none;
  }

  tr {
    border-bottom: 1px solid var(--sp-color-medium-gray);
  }

  th {
    color: var(--sp-color-purple);
    font-weight: 600;

    h2, h3, h4, h5, h6 {
      color: $white;
    }

		p {
			margin: 0;
		}

    // a {
    //   color: #0797e0;

    //   &:hover {
    //     background-color: #0797e0;
    //   }
    // }
  }

}