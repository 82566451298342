@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.story-featured-image {
  img {
    max-height: 575px;
    width: 100%;
    object-fit: cover;
    border-bottom-right-radius: 100px;
  }
}

.story-meta-wrapper {
  max-width: 1048px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;

  h1.heading {
    margin-bottom: 0.5rem;
  }

  h2.heading {
    margin-top: 0;
    font-weight: normal;
    margin-bottom: 2rem;
    font-size: 24px;
    line-height: 1.3;
  }

  .meta__wrapper {
    margin-bottom: 2.5rem;
  }
}

.story-paragraph-wrapper {
  max-width: 1048px;
  position: relative;
  margin: 0 auto;
}

.story-footer-wrapper {
  max-width: 1048px;
  margin: 0 auto;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;

  .authors__wrapper {
    h3.heading--label {
      margin-bottom: 1rem
    }
  }

  .author-card {
    font-style: normal;

    img {
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }

    &__name {
      a {
        font-style: italic;
      }
    }
  }
}

.node-teaser__story {
  padding: 1.5rem 0;
  border-bottom: solid 1px var(--sp-color-medium-gray);

  &:first-of-type {
    padding-top: 0;
  }

  .news-featured-image {
    margin-bottom: $spacer;

    img {
      border-bottom-right-radius: 32px;
    }
  }

  .node-teaser__meta {
    font-family: var(--sp-font-primary);
    color: var(--sp-color-dark-blue);
    margin-top: $spacer * 0.5;
    font-weight: 700;

    & > *,
    .date-lineview {
      display: inline;
    }

    &--dot {
      svg {
        fill: $orange;
      }
    }

    &--date {
      letter-spacing: 0.5px;
    }
  }

  .node-teaser__meta--authors {
    font-weight: 600;

    a {
      color: var(--sp-color-dark-blue);
      text-transform: none;
      font-family: var(--sp-font-primary);

      &:hover {
        color: $white;
      }
    }
  }

  .highlight {
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
}
