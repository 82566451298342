@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__callout {
  position: relative;
  color: var(--sp-color-dark-blue);
  margin-top: $spacer * 7;
  margin-bottom: $spacer * 2;

  @include media-breakpoint-up(lg) {
    min-height: 320px;
    background: var(--sp-color-light-gray);
    border-top-right-radius: 75px;
  }

  h3 {
    margin-top: 0;
    font-weight: 700;
    @include font-size(36px);
    line-height: 1.33;
  }

  .row {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .callout__image_col {
      order: 0;

      @include media-breakpoint-up(lg) {
        order: 2;
      }
    }
    .callout__content {
      border-top-right-radius: 75px;
      padding: 40px;
      padding-top: calc(2.2em + 48px);
      background: var(--sp-color-light-gray);

      @include media-breakpoint-up(lg) {
        background: unset;
        padding-top: 40px;
      }

      p {
        @include font-size(17px);
        line-height: 1.4
      }
    }

    .callout__image {
      padding: 40px;
      padding-bottom: 0;
      margin-bottom: -48px;

      @include media-breakpoint-up(lg) {
        order: 2;
        margin-bottom: 0;
        margin-top: -48px;
        padding-top: 0;
      }

      .lazyload-wrapper {
        position: relative;
  
        img {
          border-top-left-radius: 50px;
          border-bottom-right-radius: 50px;
          aspect-ratio: 3 / 2;
          object-fit: cover;
        }
      }
    }

    .col,
    .col:first-of-type,
    .col:last-of-type {
      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .callout__decorator {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  // external links
  a[href*="http"]:not([href*="sanford-files"]) {
    &:hover::after {
      background-color: var(--sp-color-blue);
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__callout {
    background: transparent;
    box-shadow: none;
    margin-top: 0;
    padding: 0;

    .callout__content {
      background: var(--sp-color-light-gray);
      padding-top: calc(2.2em + 48px);
    }

    .callout__decorator {
      display: none;
    }

    .col,
    .col:first-of-type,
    .col:last-of-type {
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 100%;
      }
    }

    .row {
      flex-direction: column;
    }

    .callout__image_col {
      order: 0;

      .callout__image {
        margin-top: 0;
        margin-bottom: -48px;
      }
    }
  }
}

.paragraph__threecolumn {
  .paragraph__callout {
    .callout__content,
    .callout__image {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
