@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__slideshow {
  .heading {
    font-family: var(--sp-font-primary);
    font-weight: 700;
    @include font-size(27px);
    line-height: 1.19;
    @include margin-bottom(2rem);
  }
}
