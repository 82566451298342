@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.iconcard {
  max-width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    max-width: 288px;
  }

  &__content {
    background-color: var(--sp-color-light-gray);
    border-radius: 8px;
    padding: 1rem;
    margin-top: -60px;
    padding-top: calc(60px + 1rem);

    h4 {
      margin-top: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
  }

  img {
    position: relative;
    display: block;
    max-width: 164px;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
}

.iconcard,
.button span {
  font-size: 14px;
  line-height: 24px;
}
