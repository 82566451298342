@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";

header {
  color: var(--sp-color-dark-blue);
  background-color: $white;

  .navbar-light .navbar-toggler {
    background-color: var(--sp-color-purple);
  }

  .header_menu_wrapper {
    background-color: var(--sp-color-purple);
  }

  .search-form {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-self: center;
    height: 48px;
    order: 1;
    width: 100%;
    position: relative;
    background: $white;
    margin-right: calc(var(--bs-gutter-x, 0.85rem) * -1);
    margin-left: calc(var(--bs-gutter-x, 0.85rem) * -1);

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
      width: 5%;
      padding-top: 5px;
      padding-bottom: 1px;
      order: 2;
      background: unset;
    }

    svg {
      width: 23px;
      height: 23px;
    }

    .search-collapse-form {
      display: flex;
      position: relative;
      width: 100%;
      padding: 0 0.8em 0 0.9em;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(lg) {
        &.fade:not(.show),
        &.fade {
          opacity: 1;
        }
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 45px;
        right: 0;
        width: 310px;
        padding: 0.75rem 1rem;
        background-color: $white;
        box-shadow: 0 4px 12px rgb(0 0 0 / 40%);
        border-radius: 100px;
        color: var(--sp-color-dark-blue);
      }
    }

    form {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    input[type="search"] {
      color: var(--sp-color-dark-blue);
      border: none;
      width: 90%;

      &::placeholder {
        color: inherit;
        opacity: 1;
      }

      @include media-breakpoint-up(lg) {
        width: 270px;
        color: var(--sp-color-dark-blue);
      }

      &:focus,
      &:focus-visible {
        box-shadow: none;

        // border: none;
        outline-color: transparent;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .search-collapse-button {
      display: none;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      border: 1px solid $white;
      background-color: var(--sp-color-purple);
      color: $white;
      padding: 0;
      width: 32px;
      height: 32px;

      &.open {
        background-color: $white;

        svg {
          fill: var(--sp-color-purple);
        }
      }

      @include media-breakpoint-up(lg) {
        display: flex;
      }

      &:focus {
        outline: solid;
      }
    }

    button[type="submit"] {
      padding: 0;
      height: 100%;

      svg {
        fill: var(--sp-color-purple);
      }
    }
  }
}

.logo {
  &-area-wrapper {
    padding: $spacer * 0.7 0 $spacer * 0.7 $spacer;
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
      padding-left: 0;
    }

    img {
      width: 100%;
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: 1fr;
    grid-column-gap: 25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: start;
  }

  &-mobile {
    display: block;
    width: 100%;
    max-width: 500px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 40%;
      display: flex;
      order: 1;
    }
  }
}

.header__logo {
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: center;
  justify-self: end;
}

.utility_menu_wrapper {
  order: 3;

  @include media-breakpoint-up(lg) {
    order: 2;
    padding: 0;
  }
}

.header_menu_wrapper {
  order: 2;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    order: 4;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.header_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 200;

  .navbar-collapse {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      background-color: var(--sp-color-light-gray);
    }

    &:not(.show) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      order: 1;
      padding: 0;
    }

    .header-top {
      display: flex;
      align-items: center;
      order: 3;
    }

    .header-bottom {
      display: flex;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }
  }

  .navbar {
    width: 100%;
    padding: 0;
    flex-flow: wrap;
  }

  .navbar-nav {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    order: 2;

    @include media-breakpoint-up(lg) {
      align-items: center;
      order: 1;
    }
  }
}

.actionmenu  {
  .nav-item {
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      margin-right: 0.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .nav-link {
    @extend .button;
    @extend .button--cta;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
