@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__newslist {
  .newslist {

    &__title {
      @include heading-base;
    }

    &__wrapper {
      flex-direction: column;
      display: flex;
      margin-bottom: $spacer*4;
    }

    &__col {
      width: 100%;

      @include media-breakpoint-up(lg) {
        &:first-of-type {
          width: 100%;
        }
      }
    }
  }
}


div[class^="col-"] .newslist {
  &__wrapper {
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }

  &__col {
    width: 100%;
  }
}

.paragraph__twocolumn .paragraph__newslist .newslist {
  &__col {
      width: 100%;
    }
}
