@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.page-title__wrapper {
  display: flex;
  background-color: var(--sp-color-light-purple);

  .page-title {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--sp-color-dark-blue);
    padding-top: 0.5 * $spacer;
    padding-bottom: 0.5 * $spacer;
  }
}
