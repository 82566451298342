@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";

.paragraph__hero-video-text {
  .row {
      flex-direction: column-reverse;

      @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
      }
  }

  .col-lg-8.col-sm-12 {
      padding: 0;

      @include media-breakpoint-up(lg) {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }
  }
  .col-lg-4.col-sm-12 {
    background-color: var(--sp-color-green);
    padding: 0;

    @include media-breakpoint-up(lg) {
        border-bottom-right-radius: 100px;
    }
  }

  .hero-text {
    color: #fff;
    height: 100%;
    padding: 2.5rem 1.5rem;

    .eyebrow {
      margin: 0;
      color: var(--sp-color-dark-blue);
    }

    h2 {
        @include font-size(40px);
    }

    h3 {
        @include font-size(24px);
    }

    h2,
    h3 {
        font-weight: 300;
        color: var(--sp-color-dark-blue);
      margin-top: 0;
      margin-bottom: 8px;
    }

    a {
        &.button {
          @extend .button--with-icon;

          color: var(--sp-color-dark-blue);
        }
      }
  }
}
