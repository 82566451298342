@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.person-card {
  padding: $spacer * 1.5 0;
  border-top: solid 1px var(--sp-color-light-gray);
  width: 100%;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 18% 82%;
  }

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  h3 {
    line-height: 1.2;
    @include font-size(20px);

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    a {
        color: var(--sp-color-blue);
    }
  }

  &-position {
    font-family: var(--sp-font-primary);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  &-image {
    padding-bottom: $spacer;

    @include media-breakpoint-up(md) {
      padding-right: $spacer * 2;
      padding-bottom: 0;
    }

    img {
      width: 100%;
      max-width: 200px;
      border-radius: 50%;
    }
  }

  svg {
    color: $shale-blue;
    margin-right: 0.3em;
    width: 24px;
    height: 24px;
  }

  &-email,
  &-phone {
    font-style: italic;
    margin-bottom: $spacer * 0.7;
  }

  &-phone {
    a {
      color: var(--sp-color-dark-blue);
    }
  }

  &__content {
    width: 100%;
  }
}

.personlist__wrapper {
  display: flex;
  flex-direction: column;

  &--grid {
    flex-direction: row;
    flex-wrap: wrap;

    .person-card,
    .person-card:first-of-type {
      width: 100%;
      padding-top: 0;
      border-top: none;

      @include media-breakpoint-up(md) {
        display: block;
        width: 33%;
        padding-right: 2%;
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }

      h3 {
        @include margin-top(2rem);
      }

      .person-card-position {
        font-size: 1rem;
      }
    }
  }
}

.paragraph__personlist {
  margin-bottom: $spacer * 2;

  &:first-of-type {
    margin-top: $spacer * 2;
  }

  h3 {
    text-transform: initial;
  }
}
