@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../Atoms/Button/Button";

.imagecard {
  transition: transform 0.2s;
  margin: $spacer * 2 0;

  &--no-image {
    .imagecard__content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__content {
    padding: $spacer * 1.5;
    background-color: var(--sp-color-light-gray);
    color: var(--sp-color-dark-blue);
    height: 100%;
    font-size: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include media-breakpoint-up(md) {
      box-shadow: none;
    }

    h4 {
      font-size: 27px;
      line-height: 1.2;
    }

    p {
      margin: 0 0 24px;
    }

    .button {
      @extend .button--with-icon;

      font-size: 14px;
      margin: 0;
    }
  }

  &__heading {
    color: var(--sp-color-purple);
    margin: 0 0 24px;
  }

  &__link {
    color: $white;
  }

  &__image {
    overflow: hidden;
    background-color: var(--sp-color-light-purple);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .lazyload-wrapper {
      display: block;

      img {
        position: relative;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}
