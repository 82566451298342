@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.page-card {
  padding: 1em 0 .8em 0;
  border-bottom: solid 1px var(--sp-color-medium-gray);

  &:last-of-type {
    border-bottom: none;
  }

  .date {
    font-family: var(--sp-font-primary);
    letter-spacing: 1px;
    @include font-size(14px);
    line-height: 1.7;
    font-weight: 700;
    text-transform: uppercase;
  }

  h4 {
    margin-top: .5rem;
    a {
      @include font-size(24px);
      line-height: 1.3;
      color: var(--sp-color-blue);
    }
  }
}