@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__pullquote {
  padding: $spacer * 3 $spacer * 3 0;
  width: 100%;
  margin: $spacer 0 $spacer * 4;
  background: url("./../../../images/PullQuote_Left.svg") left $spacer * 1.5
    no-repeat;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: $spacer * 4.5 $spacer 0;
  }

  &::after {
    content: "";
    background: url("./../../../images/PullQuote_Right.svg") no-repeat;
    height: 24px;
    width: 184px;
    display: block;
    position: absolute;
    right: 0;
  }

  p {
    color: var(--sp-color-purple);
    font-style: italic;
    font-weight: 500;
    @include font-size(24px);

    &.pullquote-attribution {
      font-family: var(--sp-font-primary);
      color: var(--sp-color-blue);
      @include font-size(14px);
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

blockquote {
  @extend .paragraph__pullquote;
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__pullquote {
    padding: 70px 20px 40px;

    @include media-breakpoint-down(md) {
      padding: 70px 20px;
    }
  }
}