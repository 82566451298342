@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__imagetext {
    display: flex;
    margin: $spacer * 2 0 $spacer * 3 0;
    margin-top: 0;
  
    .callout {
      display: flex;
      flex-direction: column;
  
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
  
      &__image {
        @include media-breakpoint-up(md) {
          min-width: 50%;
          max-width: 50%;
          padding-right: 2rem;
        }
  
        margin-bottom: $spacer;
  
        img {
          object-fit: cover;
          flex-shrink: 0;
          min-width: 100%;
          min-height: 100%;
          border-top-left-radius: 4rem;
          border-bottom-right-radius: 4rem;
        }
      }
  
      &__heading {
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
  
      &__content {
        p {
          line-height: 1.4;
        }
      }
  
      &.image-right {
        flex-direction: column;
  
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
  
        .callout__image {
          @include media-breakpoint-up(md) {
            padding-right: 0;
            padding-left: 2rem;
          }
        }
      }
    }
  
    .col-md-8,
    .col-md-4 {
      padding: 0;
    }
  }
  
  // override/additional styling for 2/3 columns
  .paragraph__twocolumn,
  .paragraph__threecolumn {
    flex-direction: column !important;
  
    .col,
    .col-md-6 {
      @media (max-width: 992px) {
        flex-basis: initial;
        flex-grow: initial;
        max-width: 100%;
      }
    }
  
    .callout {
      flex-direction: column !important;
  
      &__image {
        height: inherit;
        align-self: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        padding-right: 0;
  
        @media (max-width: 992px) {
          height: 100%;
        }
      }
  
      .button {
        padding: 5px;
      }
    }
  }
  