@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

@import "~bootstrap/scss/carousel";

.slider {

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;

    &:hover {
      background-color: transparent;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    position: absolute;
    padding: $spacer * 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
      width: 60px;
      height: 60px;
    }
  }

  .carousel-control-prev-icon {
    left: 24px;
    background: url("./../../../images/slideshow_prev.svg") center center
      no-repeat;
    background-size: 40px 40px;

    @include media-breakpoint-up(sm) {
      background-size: 60px 60px;
    }
  }
  .carousel-control-next-icon {
    right: 24px;
    background: url("./../../../images/slideshow_next.svg") center center
      no-repeat;
    background-size: 40px 40px;

    @include media-breakpoint-up(sm) {
      background-size: 60px 60px;
    }
  }

  .carousel-inner {
		// max-height: 80vh;
		
    .carousel-caption {
    border-top-left-radius: 25px;
      background-color: $white;
      color: var(--sp-color-dark-blue);
      font-family: var(--sp-font-primary);
      padding: $spacer $spacer * 1.5;
      bottom: 35px;
      right: 0;
      left: 76px;
      @include font-size(14px);
      font-weight: 500;
      text-align: left;

      p {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        position: static;
        padding: 0;
      }
    }
  }

  .custom-indicators {
    margin-top: 1.1429em;
    font-family: var(--sp-font-primary);
    text-transform: uppercase;
    @include font-size(14px);
    font-weight: 700;
    color: var(--sp-color-dark-blue);
    line-height: 1.71;
    letter-spacing: 1px;
  }

  .carousel-indicators {
    display: none;
    li {
      background-color: $dark-gray;
    }
  }

  .image {
    min-height: 400px;
  }
}